export const getImageUrl = (record, file, params = {}) => {
  let paramString = Object.keys(params)
    .map(key => `${key}:${params[key]}`)
    .join('/');
  if (paramString) paramString += '/';
  const base64path = btoa(`${record.collectionId}/${record.id}/${file}`)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  return `https://images.saengcaday.cdn.misae.us/insecure/${paramString}${base64path}`;
};

export const getGumletUrl = (record, file, params = {}) => {
  const paramString = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  const param = paramString ? `?${paramString}` : '';
  return `https://saengcaday.gumlet.io/${record.collectionId}/${record.id}/${file}${param}`;
};

export const getFullSizeImageUrl = (record, file) => {
  return `https://image-original.saengcaday.cdn.misae.us/${record.collectionId}/${record.id}/${file}`;
};
