import React, { useState } from 'react';
import './index.css';
import logo from '../../assets/images/main-logo-gif.gif';

const InfoModal = ({ shown, onHide }) => {
  const [isShown, setIsShown] = useState(shown);

  const hide = () => {
    setIsShown(!isShown);
    onHide();
  };

  const handleKeyDown = e => {
    if (e.keyCode === 27) {
      hide();
    }
  };

  const handleCopyClipBoard = () => {
    const link = 'https://www.saengcaday.com';
    try {
      navigator.clipboard.writeText(link).then(res => {
        alert('링크 복사 완료!');
      });
    } catch (error) {
      alert('복사 실패!!');
    }
  };

  const modalContent = (
    <div className="info-modal-wrapper w-full relative flex flex-col items-center justify-center text-gray-600-color">
      <h3 className="text-base font-semibold text-center">
        환영합니다!
        <br />
        🙂 생카데이입니다 🙂
      </h3>
      <img
        src={logo}
        alt=""
        className="2xs:w-20 2xs:h-20 xs:w-24 xs:h-24 2sm:w-28 2sm:h-28 sm:w-32 sm:h-32 tb:w-40 tb:h-40 lg:w-48 lg:h-48"
      />
      <ul className="info-modal-text">
        <li>
          <h3 className="text-[0.8rem]">
            <strong>크롬 또는 사파리 브라우저</strong>
          </h3>
          <p className="text-[0.6rem] font-light">사용을 권장드립니다</p>
        </li>
        <li>
          <button
            className="bg-[#454545] px-5 py-2 text-white text-[0.8rem] font-medium rounded-full"
            onClick={handleCopyClipBoard}
          >
            링크 복사하기
          </button>
        </li>
      </ul>
    </div>
  );

  return (
    <div className="modal-wrapper" onClick={hide} onKeyDown={handleKeyDown}>
      <div className="modal-section">{modalContent}</div>
      <div className="modal-dimmed" onClick={hide} />
    </div>
  );
};

export default InfoModal;
