import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './index.css';
import Header from '../../components/Header/Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { getImageUrl, getFullSizeImageUrl } from '../../lib/optimizer';
import PocketBase from 'pocketbase';
const pb = new PocketBase('https://saengcaday-api.b-cdn.net');

// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const MapLink = ({ event }) => (
  <a
    href={`https://map.kakao.com/link/map/${event.expand.cafe.name},${event.expand.cafe.latitude},${event.expand.cafe.longitude}?q=${event.expand.cafe.latitude},${event.expand.cafe.longitude}`}
    target="_blank"
    rel="noopener noreferrer"
    className="text-[#3c82f6ff]"
  >
    바로 길찾기로 이동하기
  </a>
);

const Info = () => {
  const { id } = useParams();
  const [eventInfo, seteventInfo] = useState(null);

  useEffect(() => {
    pb.collection('events')
      .getOne(id, {
        expand: 'cafe,hosts',
      })
      .then(res => {
        seteventInfo(res);
      });
  }, [id]);

  if (!eventInfo) {
    return (
      <div>
        <Header title="Event Information" />
        <div className="w-full flex flex-col p-8 items-center justify-center text-2xl">
          <p>Event not found</p>
        </div>
      </div>
    );
  }

  const posterData = [eventInfo.poster_main, ...eventInfo.poster_additional];
  const filteredPosterData = posterData.filter(image => image !== '');
  const convertDateString = date => {
    const dateObj = new Date(date);
    return `${dateObj.getFullYear()}년 ${
      dateObj.getMonth() + 1
    }월 ${dateObj.getDate()}일`;
  };
  return (
    <div className="w-full min-h-screen bg-NoteBg bg-repeat-y bg-contain font-medium">
      <Header title="생일카페 정보" />
      <div className="w-full h-auto relative flex flex-col py-10 items-center justify-center">
        <div className="flex justify-center items-center 2xs:mt-8 xs:mt-12 2sm:mt-14 sm:mt-16 tb:mt-16 lg:mt-20 xl:mt-28">
          <img
            src={getImageUrl(eventInfo, eventInfo.poster_main, { w: 500 })}
            alt={eventInfo.expand.cafe.name}
            className="2xs:w-[228px] xs:w-[260px] 2sm:w-[300px] sm:w-[360px] tb:w-[400px] lg:w-[440px] h-auto"
            onClick={() =>
              (window.location.href = getFullSizeImageUrl(
                eventInfo,
                eventInfo.poster_main,
              ))
            }
          />
        </div>
        <div className="w-full h-auto 2xs:px-[2px] 2xs:py-6 xs:px-[2px] xs:py-8 2sm:px-[2px] 2sm:py-8 sm:px-[40px] sm:py-12 tb:px-[120px] tb:py-16 lg:px-[20px] lg:py-20 xl:px-[40px] text-center text-base">
          <ul className="flex flex-col gap-6 sm:gap-8 tb:gap-10 lg:gap-8 text-[14px] sm:text-[16px] tb:text-[18px] lg:text-[18px]">
            <li className="flex flex-col items-center 2xs:gap-1 xs:gap-1 2sm:gap-1 gap-2">
              <div className="info-tag w-[88px] h-7 sm:w-[100px] sm:h-8 tb:w-28 tb:h-8 lg:w-28 lg:h-8 px-4 text-white   flex items-center justify-center">
                카페이름
              </div>
              {`${eventInfo.expand.cafe.name}`}
            </li>
            <li className="flex flex-col items-center 2xs:gap-1 xs:gap-1 2sm:gap-1 gap-2">
              <div className="info-tag w-[88px] h-7 sm:w-[100px] sm:h-8 tb:w-28 tb:h-8 lg:w-28 lg:h-8 px-4 text-white   flex items-center justify-center">
                위치
              </div>
              {`${eventInfo.expand.cafe.position}`}
            </li>
            <li className="flex flex-col items-center 2xs:gap-1 xs:gap-1 2sm:gap-1 gap-2">
              <div className="info-tag w-[88px] h-7 sm:w-[100px] sm:h-8 tb:w-28 tb:h-8 lg:w-28 lg:h-8 px-4 text-white   flex items-center justify-center">
                주소
              </div>
              {`${eventInfo.expand.cafe.address}`}
            </li>
            <li className="flex flex-col items-center 2xs:gap-1 xs:gap-1 2sm:gap-1 gap-2">
              <div className="info-tag w-[88px] h-7 sm:w-[100px] sm:h-8 tb:w-28 tb:h-8 lg:w-28 lg:h-8 px-4 text-white   flex items-center justify-center">
                날짜
              </div>
              {`${convertDateString(eventInfo.start_date)} ~ ${convertDateString(eventInfo.end_date)}`}
            </li>
            <li className="flex flex-col items-center 2xs:gap-1 xs:gap-1 2sm:gap-1 gap-2">
              <div className="info-tag w-[88px] h-7 sm:w-[100px] sm:h-8 tb:w-28 tb:h-8 lg:w-28 lg:h-8 px-4 text-white   flex items-center justify-center">
                영업시간
              </div>
              {`${eventInfo?.open_hours?.info || '정보 없음'}`}
              <br />
              {/* {eventInfo?.open_hours?.hours?.map((hour, index) => (
                <div key={index}>
                  {`${hour.date} - ${hour.open_hours[0].open} ~ ${hour.open_hours[0].close}`}
                </div>
              ))} */}
            </li>
            <li className="flex flex-col items-center 2xs:gap-1 xs:gap-1 2sm:gap-1 gap-2">
              <div className="info-tag w-[108px] h-7 sm:w-[116px] sm:h-8 tb:w-32 tb:h-8 lg:w-32 lg:h-8 px-4 text-white   flex items-center justify-center">
                트위터 계정
              </div>
              {eventInfo.expand.hosts.map((host, index) => (
                <a
                  key={index}
                  href={
                    index === 0
                      ? eventInfo.info_url
                      : `https://twitter.com/${host.twitter}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline cursor-pointer"
                >
                  @{`${host.name}`}
                </a>
              ))}
            </li>
            <li className="mt-10 flex flex-col items-center 2xs:gap-1 2sm:gap-1 gap-2">
              <div className="info-tag w-[108px] h-7 sm:w-[116px] sm:h-8 tb:w-32 tb:h-8 lg:w-32 lg:h-8 px-4 text-white   flex items-center justify-center">
                전체 포스터
              </div>
              <div className="w-full h-auto flex py-6 lg:py-4 lg:px-[200px] xl:px-[480px] items-center justify-center">
                <Swiper
                  style={{
                    '--swiper-pagination-color': '#454545',
                    '--swiper-navigation-color': '#454545',
                  }}
                  modules={[Navigation, Pagination]}
                  spaceBetween={10}
                  slidesPerView={1}
                  loop={true}
                  navigation={true}
                  pagination={{ clickable: true }}
                >
                  {filteredPosterData.map((image, index) => (
                    <SwiperSlide
                      key={index}
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={getImageUrl(eventInfo, image, { w: 500 })}
                        alt={`Poster ${index + 1}`}
                        className="h-auto 2xs:w-[243px] xs:w-[270px] 2sm:w-[291px] sm:w-[390px] tb:w-[390px] lg:w-[420px]  xl:w-[510px] "
                        onClick={() =>
                          (window.location.href = getFullSizeImageUrl(
                            eventInfo,
                            image,
                          ))
                        }
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </li>
            <li className="mt-10 flex flex-col items-center 2xs:gap-1 2sm:gap-1 gap-2">
              <div className="info-tag w-[108px] h-7 sm:w-[116px] sm:h-8 tb:w-32 tb:h-8 lg:w-32 lg:h-8 px-4 text-white   flex items-center justify-center">
                바로 길찾기
              </div>
              <MapLink event={eventInfo} />
              {/* <div className="w-full py-6 lg:py-4 flex justify-center">
                {isSafari ? (
                  <MapLink cafe={eventInfo} />
                ) : (
                  <div>
                    <div className="xs:hidden 2sm:hidden sm:hidden tb:hidden lg:hidden">
                      <iframe
                        title={`Map of ${eventInfo.name}`}
                        width="240"
                        height="380"
                        src={`https://map.kakao.com/link/map/${eventInfo.name},${eventInfo.latitude},${eventInfo.longitude}?q=${eventInfo.latitude},${eventInfo.longitude}`}
                        frameBorder="0"
                        style={{ border: '.6px solid #ccc' }}
                        allowFullScreen
                      />
                    </div>
                    <div className="2xs:hidden 2sm:hidden sm:hidden tb:hidden lg:hidden">
                      <iframe
                        title={`Map of ${eventInfo.name}`}
                        width="300"
                        height="440"
                        src={`https://map.kakao.com/link/map/${eventInfo.name},${eventInfo.latitude},${eventInfo.longitude}?q=${eventInfo.latitude},${eventInfo.longitude}`}
                        frameBorder="0"
                        style={{ border: '.6px solid #ccc' }}
                        allowFullScreen
                      />
                    </div>
                    <div className="2xs:hidden xs:hidden sm:hidden tb:hidden lg:hidden">
                      <iframe
                        title={`Map of ${eventInfo.name}`}
                        width="340"
                        height="480"
                        src={`https://map.kakao.com/link/map/${eventInfo.name},${eventInfo.latitude},${eventInfo.longitude}?q=${eventInfo.latitude},${eventInfo.longitude}`}
                        frameBorder="0"
                        style={{ border: '.6px solid #ccc' }}
                        allowFullScreen
                      />
                    </div>
                    <div className="2xs:hidden xs:hidden 2sm:hidden tb:hidden lg:hidden">
                      <iframe
                        title={`Map of ${eventInfo.name}`}
                        width="480"
                        height="600"
                        src={`https://map.kakao.com/link/map/${eventInfo.name},${eventInfo.latitude},${eventInfo.longitude}?q=${eventInfo.latitude},${eventInfo.longitude}`}
                        frameBorder="0"
                        style={{ border: '.6px solid #ccc' }}
                        allowFullScreen
                      />
                    </div>
                    <div className="2xs:hidden xs:hidden 2sm:hidden sm:hidden lg:hidden">
                      <iframe
                        title={`Map of ${eventInfo.name}`}
                        width="700"
                        height="640"
                        src={`https://map.kakao.com/link/map/${eventInfo.name},${eventInfo.latitude},${eventInfo.longitude}?q=${eventInfo.latitude},${eventInfo.longitude}`}
                        frameBorder="0"
                        style={{ border: '.6px solid #ccc' }}
                        allowFullScreen
                      />
                    </div>
                    <div className="2xs:hidden xs:hidden 2sm:hidden sm:hidden tb:hidden">
                      <iframe
                        title={`Map of ${eventInfo.name}`}
                        width="960"
                        height="800"
                        src={`https://map.kakao.com/link/map/${eventInfo.name},${eventInfo.latitude},${eventInfo.longitude}?q=${eventInfo.latitude},${eventInfo.longitude}`}
                        frameBorder="0"
                        style={{ border: '.6px solid #ccc' }}
                        allowFullScreen
                      />
                    </div>
                    <div className="2xs:hidden xs:hidden 2sm:hidden sm:hidden tb:hidden lg:hidden">
                      <iframe
                        title={`Map of ${eventInfo.name}`}
                        width="1280"
                        height="1000"
                        src={`https://map.kakao.com/link/map/${eventInfo.name},${eventInfo.latitude},${eventInfo.longitude}?q=${eventInfo.latitude},${eventInfo.longitude}`}
                        frameBorder="0"
                        style={{ border: '.6px solid #ccc' }}
                        allowFullScreen
                      />
                    </div>
                  </div>
                )}
              </div> */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Info;
