import React from 'react';
import Header from '../../components/Header/Header';

const Contact = () => {
  return (
    <div className="w-full min-h-screen bg-NoteBg bg-repeat-y bg-contain">
      <Header title="문의하기" />
      <div className="w-full h-auto relative flex flex-col 2xs:pt-20 xs:pt-24 2sm:pt-28 sm:pt-28 tb:pt-28 lg:pt-32 xl:pt-40 pb-8 items-center justify-center">
        <div className="w-full h-auto leading-[150%] 2xs:px-[10px] xs:px-[18px] 2sm:px-[24px] sm:px-[36px] tb:px-[100px] lg:px-[20px] xl:px-[40px] text-center text-[32px] 2xs:text-[10px] xs:text-[14px] 2sm:text-[16px] sm:text-[22px] tb:text-[26px] font-MainFont text-black flex flex-col justify-center items-center relative">
          <p className="2xs:mb-3 xs:mb-4 2sm:mb-4 sm:mb-6 tb:mb-8 mb-12 text-[40px] 2xs:text-[14px] xs:text-[18px] 2sm:text-[20px] sm:text-[28px] tb:text-[32px]">
            💬 생카 제보와 문의사항 안내 🙂
          </p>
          <br />
          생카데이에 없는 생카 제보 또는
          <br />
          문의사항과 건의사항, 피드백 모두 환영합니다
          <br />
          <br />
          감사합니다!
          <div className="w-full items-center flex flex-col gap-2">
            <a
              href="https://linkbio.co/6010904ydlS94"
              className="mt-10 tb:mt-16 lg:mt-20"
              rel="noreferrer"
              target="_blank"
            >
              <button className="bg-[#454545] px-4 py-2 text-white rounded-2xl">
                문의사항은 Contact Mei
              </button>
            </a>
            <a
              href="https://twitter.com/saengcaday"
              className="mt-3 tb:mt-4 lg:mt-6 text-blue-600 underline font-MainFont 2xs:text-[12px] xs:text-[14px] 2sm:text-[16px] sm:text-[16px] tb:text-[18px] lg:text-[24px]"
              rel="noreferrer"
              target="_blank"
            >
              생카데이 공식 트위터
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
