import React from 'react';
import logo from '../../assets/images/main-logo.png';
import kakao from '../../assets/images/icon-kakao.png';
import twitter from '../../assets/images/icon-twitter.png';
import clip from '../../assets/images/icon-clip.png';

const Sidebar = ({ isOpen }) => {
  const shareKakao = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        kakao.init('84729ad99d624bfd077bed56c6e2a4d6');
      }

      kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: '생카데이',
          description:
            '생카 정보는, 생카데이로 한 번에! 같이 생일카페 갈래?!🎶',
          imageUrl: 'https://saengcaday.com/og-image.png',
          link: {
            mobileWebUrl: 'https://saengcaday.com',
            webUrl: 'https://saengcaday.com',
          },
        },
        buttons: [
          {
            title: '생일카페 구경하기🎂🥳',
            link: {
              mobileWebUrl: 'https://saengcaday.com',
              webUrl: 'https://saengcaday.com',
            },
          },
        ],
      });
    }
  };

  const shareTwitter = () => {
    let sendText = '생카 정보는, 생카데이로 한 번에! 같이 생일카페 갈래?!🥳🎶';
    let sendUrl = 'https://saengcaday.com';
    window.open(
      'https://twitter.com/intent/tweet?text=' + sendText + '&url=' + sendUrl,
    );
  };

  const handleCopyClipBoard = text => {
    try {
      navigator.clipboard.writeText(text).then(res => {
        alert('링크 복사 완료!');
      });
    } catch (error) {
      alert('복사 실패!!');
    }
  };
  return (
    <div
      id="sidebar"
      dir="rtl"
      className={`${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } z-50 fixed top-0 left-0 h-full xl:w-1/3 lg:w-5/12 tb:w-7/12 w-8/12 2xs:w-9/12 rounded-s-2xl bg-white text-black transition-transform duration-300 ease-in-out`}
    >
      <div className="w-full h-full lg:text-2xl lg:pt-16 pr-12 lg:pl-16 tb:text-2xl tb:pt-16 tb:pl-12 sm:text-xl sm:pt-12 sm:pl-14 text-base 2xs:text-sm pt-10 pl-8 2xs:pl-6 2xs:pr-6 flex flex-col">
        <a
          href="/"
          className="flex justify-end lg:mb-20 tb:mb-16 sm:mb-16 mb-10 2xs:mb-8"
        >
          <img
            src={logo}
            alt=""
            className="2xs:w-[116px] xs:w-32 2sm:w-36 sm:w-36 tb:w-56 lg:w-[264px] h-auto"
          />
        </a>
        <ul className="flex flex-col lg:gap-7 tb:gap-5 sm:gap-4 gap-3 2xs:gap-2 text-left">
          <li>
            <a href="/">홈</a>
          </li>
          <li>
            <a href="/about">서비스 소개</a>
          </li>
          <li>
            <a href="/total">전체 생일카페 리스트</a>
          </li>
          <li>
            <a href="/contact">문의하기</a>
          </li>
          <li className="w-full my-5">
            <hr className="border-1 border-gray-300 h-1" />
          </li>
          <li>SNS 공유하기</li>
          <ul className="flex flex-row justify-end list-none gap-4 lg:gap-6">
            <li onClick={() => shareKakao()}>
              <img
                src={kakao}
                alt=""
                className="cursor-pointer 2xs:w-6 2xs:h-6 xs:w-7 xs:h-7 2sm:w-8 2sm:h-8 sm:w-10 sm:h-10 tb:w-10 tb:h-10 lg:w-12 lg:h-12"
              />
            </li>
            <li onClick={() => shareTwitter()}>
              <img
                src={twitter}
                alt=""
                className="cursor-pointer 2xs:w-6 2xs:h-6 xs:w-7 xs:h-7 2sm:w-8 2sm:h-8 sm:w-10 sm:h-10 tb:w-10 tb:h-10 lg:w-12 lg:h-12"
              />
            </li>
            <li onClick={() => handleCopyClipBoard('https://saengcaday.com')}>
              <img
                src={clip}
                alt=""
                className="cursor-pointer 2xs:w-6 2xs:h-6 xs:w-7 xs:h-7 2sm:w-8 2sm:h-8 sm:w-10 sm:h-10 tb:w-10 tb:h-10 lg:w-12 lg:h-12"
              />
            </li>
          </ul>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
